import React, { useEffect, useState } from 'react';
import { Collapse, Spin } from 'antd';
import api from '../../services/api';

const { Panel } = Collapse;

const UserLicencas: React.FC = () => {
  const [years, setYears] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<Array<String>>([]);

  const fileType = 'licencas';

  const getFiles = async () => {
    const panel = document.getElementsByClassName(
      'ant-collapse-item ant-collapse-item-active'
    );
    const year = panel[0].id;

    try {
      if (files.length === 0 && !open) {
        const response = await api.get('blobFiles/files', {
          params: {
            cnpj: localStorage.getItem('cnpj'),
            fileType: 'licencas',
            year: year,
          },
        });
        setFiles(response.data);
      }
      setOpen(!open);
    } catch (error) {
      console.log(error);
    }

    setOpen(!open);
  };

  const downloadPDF = async (fileName: String) => {
    const panel = document.getElementsByClassName(
      'ant-collapse-item ant-collapse-item-active'
    );
    const year = panel[0].id;
    const cnpj = localStorage.getItem('cnpj');
    try {
      const response = await api.get('blobFiles/download', {
        params: {
          filePath: `${cnpj}/licencas/${year}/${fileName}`,
        },
      });
      const link = document.createElement('a');
      link.href = response.data;
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getYears = async () => {
      var response = await api.get('blobFiles/years', {
        params: {
          cnpj: localStorage.getItem('cnpj'),
          fileType: fileType,
        },
      });
      setYears(response.data);
      setIsloading(false);
    };
    try {
      getYears();
    } catch (error) {
      console.log(error);
    }
  }, [fileType]);

  return (
    <div style={{ display: 'flex' }}>
      <main className='main'>
        <h1 className='titleMtr'>Licenças</h1>
        {isLoading ? (
          <Spin size='large' />
        ) : years.length === 0 ? (
          <h1>Nenhum arquivo disponível no momento</h1>
        ) : (
          <Collapse accordion onChange={getFiles}>
            {years.map((year: string, index: number) => {
              return (
                <Panel id={`${year}`} header={year} key={index}>
                  {files.map((item: String, index: number) => {
                    return (
                      <div
                        className='file'
                        onClick={() => downloadPDF(item)}
                        key={index}
                      >
                        <img src='/images/icons/pdf-file.png' alt='PDF' />
                        <p>{item}</p>
                      </div>
                    );
                  })}
                </Panel>
              );
            })}
          </Collapse>
        )}
      </main>
    </div>
  );
};

export default UserLicencas;
